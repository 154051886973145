<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-title color="white">Dettaglio richiesta</ion-title>
                <ion-buttons slot="start">
                    <ion-button @click="closeModal()">
                        <ion-icon slot="start" :icon="arrowBackOutline" color="white"></ion-icon>
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <div class="page_content">
                <div class="richiesta">
                    <div class="field">
                        <div class="title">Tipologia</div>
                        <div class="value">
                            {{ richiesta.richieste_tipologia_value }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Effettuata il</div>
                        <div class="value">
                            {{ dateFormat(richiesta.richieste_creation_date) }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Data inizio</div>
                        <div class="value">
                            {{ richiesta.richieste_dal ? dateFormat(richiesta.richieste_dal) : "-" }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Ora inizio</div>
                        <div class="value">
                            {{ richiesta.richieste_ora_inizio ? richiesta.richieste_ora_inizio : "-" }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Data fine</div>
                        <div class="value">
                            {{ richiesta.richieste_al ? dateFormat(richiesta.richieste_al) : "-" }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Ora fine</div>
                        <div class="value">
                            {{ richiesta.richieste_ora_fine ? richiesta.richieste_ora_fine : "-" }}
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Stato</div>
                        <div class="value">
                            <span class="badge" :class="statoRichiesta(richiesta.richieste_stato_id)">
                                {{ richiesta.richieste_stato_value }}
                            </span>
                        </div>
                    </div>
                    <div class="field">
                        <div class="title">Protocollo INPS</div>
                        <div class="value">
                            {{ richiesta.richieste_protocollo_inps ? richiesta.richieste_protocollo_inps : "-" }}
                        </div>
                    </div>
                    <div class="field_note">
                        <div class="title">Note</div>
                        <div class="value">
                            {{ richiesta.richieste_note ? richiesta.richieste_note : "-" }}
                        </div>
                    </div>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    //IonBackButton,
    IonTitle,
    IonContent,
    IonIcon,
    IonButton,
    modalController,
} from "@ionic/vue";
import { defineComponent, onMounted, ref, computed } from "vue";
import { useRoute, useRouter } from "vue-router";

import { arrowBackOutline } from "ionicons/icons";

import moment from "moment";

import { checkField, dateFormat, dateFormatWithMinutes } from "../services/utils";

export default defineComponent({
    name: "RichiestaDetail",
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    components: {
        IonPage,
        IonHeader,
        IonToolbar,
        IonButtons,
        //IonBackButton,
        IonTitle,
        IonContent,
        IonIcon,
        IonButton,
    },
    setup(props, context) {
        const route = useRoute();
        const id = route.params.id; //richiesta_id by route params
        const router = useRouter();

        const loading = ref(false);
        const richiesta = ref([]);
        //console.log(props.data);
        richiesta.value = { ...props.data };
        //console.log(richiesta.value);

        /**
         * ! Handle close modal by clicking on Chiudi button
         */
        const closeModal = () => {
            modalController.dismiss();
        };

        const statoRichiesta = computed(() => {
            return (statusId) => {
                let className = "";
                if (statusId == 1) {
                    //attesa approvazione
                    className = "badge_warning";
                } else if (statusId == 2) {
                    //approvato
                    className = "badge_success";
                } else if (statusId == 3) {
                    //rifiutato
                    className = "badge_danger";
                }
                return className;
            };
        });

        return {
            loading,
            statoRichiesta,
            checkField,
            dateFormat,
            dateFormatWithMinutes,
            richiesta,
            arrowBackOutline,
            closeModal,
        };
    },
});
</script>

<style scoped>
ion-toolbar {
    --background: #086fa3;
    --color: #ffffff;
}
.loading_spinner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
ion-item {
    font-size: 14px;
}
ion-label {
    margin: 0;
}
.description_title {
    color: var(--ion-color-primary, #3880ff) !important;
    margin: 10px 0;
}
/* New rules */
.page_content {
    width: 100%;
    position: relative; /* to be used for new richiesta btn */
    padding: 16px;
    min-height: 100%;
    background: #f8fafc;
}
.richiesta {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    background: #ffffff;
    border-radius: 4px;
    margin-bottom: 10px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.richiesta .field {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 6px;
    margin-bottom: 10px;
    border-bottom: 1px solid #e2e8f0;
}
.richiesta .field_note {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 12px;
}
.field .title,
.field_note .title {
    font-size: 14px;
    font-weight: 500;
    color: #475569;
}
.field_note .title {
    margin-bottom: 8px;
}
.field .value,
.field_note .value {
    font-size: 14px;
}
.value .badge {
    font-size: 10px;
    font-weight: 600;
    color: #ffffff;
    padding: 4px 8px;
    border-radius: 4px;
}

.value .badge_success {
    background-color: rgb(220 252 231);
    color: rgb(34 197 94);
}
.value .badge_warning {
    background-color: #f97316;
    background-color: rgb(254 243 199);
    color: rgb(245 158 11);
}
.value .badge_danger {
    background-color: rgb(254 226 226);
    color: rgb(239 68 68);
}
ion-button {
    --color: #ffffff;
}
</style>
